import { render, staticRenderFns } from "./investment.vue?vue&type=template&id=235bab78&scoped=true&"
import script from "./investment.vue?vue&type=script&lang=js&"
export * from "./investment.vue?vue&type=script&lang=js&"
import style0 from "./investment.vue?vue&type=style&index=0&id=235bab78&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "235bab78",
  null
  
)

export default component.exports